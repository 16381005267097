// Generated by Framer (2923eb5)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, getPropertyControls, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
import * as sharedStyle from "../css/kf7bwnSqK";
const MaterialFonts = getFonts(Material);
const MaterialControls = getPropertyControls(Material);

const cycleOrder = ["I104:382;58:404", "Ou8I2Axe6"];

const serializationHash = "framer-gxsAe"

const variantClassNames = {"I104:382;58:404": "framer-v-13quw5w", Ou8I2Axe6: "framer-v-1ylje3l"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "I104:382;58:404", Mobile: "Ou8I2Axe6"}

const getProps = ({background, height, icon, iconColor, iconVisible, id, title, width, ...props}) => { return {...props, k198fzuhR: icon ?? props.k198fzuhR ?? "CheckCircle", kkyYlRCnk: background ?? props.kkyYlRCnk ?? "rgb(234, 232, 225)", M_GaAsaKn: title ?? props.M_GaAsaKn ?? "Awesome visual identities & logo(s)", PTor2jcXc: iconColor ?? props.PTor2jcXc ?? "var(--token-a378a457-09c9-4e21-8971-31a4dc6ae8ea, rgb(17, 16, 17))", uiSyDaYnE: iconVisible ?? props.uiSyDaYnE ?? true, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "I104:382;58:404"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;iconColor?: string;background?: string;iconVisible?: boolean;icon?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, M_GaAsaKn, PTor2jcXc, kkyYlRCnk, uiSyDaYnE, k198fzuhR, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "I104:382;58:404", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-13quw5w", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"I104:382;58:404"} ref={ref ?? ref1} style={{backgroundColor: kkyYlRCnk, borderBottomLeftRadius: 36, borderBottomRightRadius: 36, borderTopLeftRadius: 36, borderTopRightRadius: 36, ...style}} {...addPropertyOverrides({Ou8I2Axe6: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}>{uiSyDaYnE && (<ComponentViewportProvider ><motion.div className={"framer-5z9j0c-container"} layoutDependency={layoutDependency} layoutId={"kL504I37e-container"}><Material color={PTor2jcXc} height={"100%"} iconSearch={"Home"} iconSelection={k198fzuhR} iconStyle15={"Rounded"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"kL504I37e"} layoutId={"kL504I37e"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider>)}<RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-bloxs3"} data-styles-preset={"kf7bwnSqK"} style={{"--framer-text-alignment": "left"}}>Awesome visual identities &amp; logo(s)</motion.p></React.Fragment>} className={"framer-1mtm7dv"} data-framer-name={"Text"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"I104:382;58:407"} style={{"--framer-paragraph-spacing": "16px"}} text={M_GaAsaKn} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gxsAe.framer-83nsf, .framer-gxsAe .framer-83nsf { display: block; }", ".framer-gxsAe.framer-13quw5w { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 10px 16px 10px 12px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-gxsAe .framer-5z9j0c-container { flex: none; height: 24px; position: relative; width: 24px; }", ".framer-gxsAe .framer-1mtm7dv { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gxsAe.framer-13quw5w { gap: 0px; } .framer-gxsAe.framer-13quw5w > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-gxsAe.framer-13quw5w > :first-child { margin-left: 0px; } .framer-gxsAe.framer-13quw5w > :last-child { margin-right: 0px; } }", ".framer-gxsAe.framer-v-1ylje3l.framer-13quw5w { gap: 8px; padding: 10px 14px 10px 10px; width: 333px; }", ".framer-gxsAe.framer-v-1ylje3l .framer-1mtm7dv { flex: 1 0 0px; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gxsAe.framer-v-1ylje3l.framer-13quw5w { gap: 0px; } .framer-gxsAe.framer-v-1ylje3l.framer-13quw5w > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-gxsAe.framer-v-1ylje3l.framer-13quw5w > :first-child { margin-left: 0px; } .framer-gxsAe.framer-v-1ylje3l.framer-13quw5w > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 47
 * @framerIntrinsicWidth 330
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Ou8I2Axe6":{"layout":["fixed","auto"]}}}
 * @framerVariables {"M_GaAsaKn":"title","PTor2jcXc":"iconColor","kkyYlRCnk":"background","uiSyDaYnE":"iconVisible","k198fzuhR":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerdyPL4LF8O: React.ComponentType<Props> = withCSS(Component, css, "framer-gxsAe") as typeof Component;
export default FramerdyPL4LF8O;

FramerdyPL4LF8O.displayName = "Card Feature";

FramerdyPL4LF8O.defaultProps = {height: 47, width: 330};

addPropertyControls(FramerdyPL4LF8O, {variant: {options: ["I104:382;58:404", "Ou8I2Axe6"], optionTitles: ["Desktop", "Mobile"], title: "Variant", type: ControlType.Enum}, M_GaAsaKn: {defaultValue: "Awesome visual identities & logo(s)", displayTextArea: false, title: "Title", type: ControlType.String}, PTor2jcXc: {defaultValue: "var(--token-a378a457-09c9-4e21-8971-31a4dc6ae8ea, rgb(17, 16, 17)) /* {\"name\":\"Text\"} */", title: "Icon Color", type: ControlType.Color}, kkyYlRCnk: {defaultValue: "rgb(234, 232, 225)", title: "Background", type: ControlType.Color}, uiSyDaYnE: {defaultValue: true, title: "Icon Visible", type: ControlType.Boolean}, k198fzuhR: MaterialControls?.["iconSelection"] && {...MaterialControls["iconSelection"], defaultValue: "CheckCircle", description: undefined, hidden: undefined, title: "Icon"}} as any)

addFonts(FramerdyPL4LF8O, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...MaterialFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})